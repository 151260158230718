import React, {useEffect, useState, useContext} from 'react';


import Rating from '@mui/material/Rating';
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

const labels = {
    1: 'Отвратительно',
    2: 'Плохо',
    3: 'Нормально',
    4: 'Хорошо',
    5: 'Отлично',
};

export default function ExRating(props) {

    const [hover, setHover] = useState(-1);

    return (
        <Box
            sx={{
                '& > legend': { mt: 2, mb: 1 },
            }}
        >
        {props.legend && <Typography component="legend"
            style={{
            fontFamily: 'Rubik',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '18px',
        }}>{props.legend}</Typography>}
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Rating
                onChangeActive={(event, newHover) => {
                    setHover(newHover);
                }}
                emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                onChange={props.onChange}
                size={props.size}
                style={props.style, {color: '#E9D949'}}
                name={props.name}
                value={props.value}
                sx={props.sx}
            />
            {props.desc !== false && props.value !== null && (
                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : props.value]}</Box>
            )}
        </Box>
        </Box>
    );
}