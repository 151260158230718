import React, {useEffect, useState, useContext} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Logo from './assets/logo.svg'
import compLogo from './assets/comp.svg'
import cat from './assets/cat.svg'

import GoogleIcon from './assets/google.svg'
import YandexIcon from './assets/ya.svg'
import GisIcon from './assets/2gis.svg'

import './App.css';
import ExRating from "./components/Rating";


import {Route, Routes, useSearchParams, Link} from "react-router-dom";
import {styled} from "@mui/material/styles";

export const mainContext = React.createContext({});


//const urlApi = window.urlApi || '/b/';
const urlApi = '/b/'
//const logo = window.logo || '/images/logo.png';


const Image = styled('span')(({ theme }) => ({
    marginBottom: '30px',
    padding: '15px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    noWrap: true,
    boxSizing: 'border-box',
    background: '#F3F3F3',
    border: '1px solid #B9B7B7',
    borderRadius: '10px'
}));

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}


function App() {


    const [rating, setRatingState] = useState(0);
    const [token, setToken] = useState(localStorage.getItem('t'));

    const [quality, setQualityState] = useState(localStorage.getItem('quality'));
    const [staff, setStaffQualityState] = useState(localStorage.getItem('staff'));
    const [cost, setCostState] = useState(localStorage.getItem('cost'));
    const [comment, setCommentState] = useState(localStorage.getItem('comment'));
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            const {innerWidth: width, innerHeight: height} = window;
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])


    const sendApi = async(data) => {
        try {
            let res = await fetch(urlApi, {
                method: "POST",
                body: JSON.stringify({
                    token: token,
                    ...data
                }),
            });
            if (res.status === 200) {
                return true;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    const setRating = (e, value) => {
        if (value > 0) {
            setRatingState(value);
            localStorage.setItem(e.target.name, value);
        }
    }


    const setQuality = (e, value) => {
        if (value > 0) {
            setQualityState(value);
            localStorage.setItem(e.target.name, value);
        }
    }

    const setStaffQuality = (e, value) => {
        if (value > 0) {
            setStaffQualityState(value);
            localStorage.setItem(e.target.name, value);
        }
    }

    const setCost = (e, value) => {
        if (value > 0) {
            setCostState(value);
            localStorage.setItem(e.target.name, value);
        }
    }

    const setComment = (e) => {
        let value = e.target.value;
        setCommentState(value);
        localStorage.setItem(e.target.name, value);
    }


    return (
        <mainContext.Provider value=
                                  {{
                                      rating, setRating, token, setToken,
                                      quality, setQuality, staff, setStaffQuality,
                                      cost, setCost, setComment, comment,
                                      setStaffQualityState, setQualityState, setCostState,
                                      setCommentState, setRatingState, windowDimensions, sendApi
                                  }}
        >
            <div className="App" style={{ width: '100vw', maxWidth: 500}}>
                <header className="App-header">
            <img src={Logo} style={{ marginLeft: '27px', marginTop: '30px', marginBottom: '20px' }}/>
                </header>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="review" element={<Review/>}/>
                    <Route path="comment" element={<Comment/>}/>
                    <Route path="*" element={<Main/>}/>
                </Routes>
            </div>
        </mainContext.Provider>
    );
}

function Main() {

    const main = useContext(mainContext);




    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let t = searchParams.get("t")
        if (t !== localStorage.getItem('t')) {
            localStorage.clear();
            localStorage.setItem('quality', false)
            localStorage.setItem('staff', false)
            localStorage.setItem('cost', false)
            localStorage.setItem('comment', '')
            main.setQualityState(false)
            main.setStaffQualityState(false)
            main.setCostState(false)
            main.setCommentState('')
            main.setRating(0);
        } else {
            main.setRatingState(localStorage.getItem('rating'))
        }
        localStorage.setItem('t', t);
        main.setToken(t)
        main.sendApi({type: 'open', width: main.windowDimensions.width, height: main.windowDimensions.height });
    }, [])


    useEffect(() => {
        main.sendApi({type: 'rating', rating: main.rating})
    }, [main.rating])

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
            flexDirection: 'column',
            p: 2,
            m: 3, boxShadow: '0px 3.7913px 20.8522px -3.7913px rgba(0, 0, 0, 0.15)', borderRadius: '7.58261px', minHeight: '65vh', width: '80vw', maxWidth: '450px',
        }}>
  {/*          <Typography component="div" variant="h5" style={{ fontWeight: 'bold', margin: '40px 0 40px 0' }} gutterBottom>
                Улучшения начинаются с Вас!
            </Typography>*/}
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
            }}>
            <img src={compLogo} alt="Logo" style={{ marginTop: '48px', marginBottom: '48px', marginLeft: '20px' }}/>
            </Box>

            <Typography component="div" style={{
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: 800,
                fontSize: '22px',
                lineHeight: '26px',
                marginBottom: '10px'
                }}>
                Оцените качество нашей работы
            </Typography>

            <ExRating sx={{fontSize: (main.windowDimensions.width > 500 ? '90px' : '15vw')}} name="rating" desc={false} value={Number(main.rating)}
                      onChange={main.setRating}/>
            <Button component={Link} to={(main.rating > 3) ? 'review' : 'comment'}
                    style={{
                        borderRadius: '7.58261px',
                        width: '100%',
                        height: '6vh',
                        maxHeight: '50px',
                        background: '#0055FE', color: 'white', fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '14px',
                        lineHeight: '17px',
                        marginBottom: '20px',
                        marginTop: '30px',
                        textTransform: 'none'
                        }} disabled={!main.rating}>
                    Оценить
            </Button>

                <Typography component="div" style={{
                    fontFamily: 'Rubik',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '15px',
                    lineHeight: '18px'
                }}>
                    Нам важно Ваше мнение! Заранее благодарим Вас за любую оценку!
                </Typography>
        </Box>
    );
}

function Review() {
    const main = useContext(mainContext);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            ml: 4,
            mr: 3,
        }}>
            <Typography component="div" style={{
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '18px',
                marginTop: '10px'
            }}>
                Спасибо за высокую оценку!
            </Typography>
            <Typography component="div" style={{
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: 800,
                fontSize: '22px',
                lineHeight: '26px',
                marginTop: '20px',
                marginBottom: '40px'
            }}>
                Вы бы нам очень помогли, если бы оставили отзыв в интернете. Это займет немного времени
            </Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: '100%',
            }}>
                <Button onClick={(e) => {window.open(`${urlApi}?p=yandex&t=${main.token}`, '_blank')}} style={{
                    marginBottom: '30px',
                    padding: '15px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    noWrap: true,
                    boxSizing: 'border-box',
                    background: '#F3F3F3',
                    color: '#000000',
                    border: '1px solid #B9B7B7',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}>
                    <img src={YandexIcon}/>
                    <Typography component="div" style={{
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14x',
                        lineHeight: '17px',
                        marginLeft: '15px'
                    }}>
                    Оставить отзыв на Яндекс
                    </Typography>
                </Button>
                <Button onClick={(e) => {window.open(`${urlApi}?p=google&t=${main.token}`, '_blank')}} style={{
                    marginBottom: '30px',
                    padding: '15px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    noWrap: true,
                    boxSizing: 'border-box',
                    background: '#F3F3F3',
                    color: '#000000',
                    border: '1px solid #B9B7B7',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}>
                <img src={GoogleIcon}/>
                    <Typography component="div" style={{
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14x',
                        lineHeight: '17px',
                        marginLeft: '15px'
                    }}>
                        Оставить отзыв на Google
                    </Typography>
                </Button>
                <Button onClick={(e) => {window.open(`${urlApi}?p=2gis&t=${main.token}`, '_blank')}} style={{
                    marginBottom: '30px',
                    padding: '15px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    noWrap: true,
                    boxSizing: 'border-box',
                    background: '#F3F3F3',
                    color: '#000000',
                    border: '1px solid #B9B7B7',
                    borderRadius: '10px',
                    textTransform: 'none'
                }}>
                <img src={GisIcon}/>
                    <Typography component="div" style={{
                        fontFamily: 'Rubik',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '14x',
                        lineHeight: '17px',
                        marginLeft: '10px'
                    }}>
                        Оставить отзыв на 2GIS
                    </Typography>
                </Button>
            </Box>
        </Box>
    );

}

function Comment() {

    const main = useContext(mainContext);

    const [finish, setFinish] = useState(false);

    let handleSubmit = async (e) => {
        e.preventDefault();
        setFinish(true);
        await main.sendApi({
            type: 'comment',
            rating: main.rating,
            quality: main.quality,
            staff: main.staff,
            cost: main.cost,
            comment: main.comment,
        })

    };

    useEffect(() => {
        main.sendApi({
            type: 'moreRating',
            rating: main.rating,
            quality: main.quality,
            staff: main.staff,
            cost: main.cost,
            comment: main.comment,
        })
    }, [main.quality, main.staff, main.cost, main.rating])

    return (
        <>
            {!finish ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        mx: 4,
                        '& > legend': {mt: 3, mb: 1},
                    }}>
                        <Typography component="div" style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '15px',
                            lineHeight: '18px',
                            marginTop: '10px'
                        }}>
                            Спасибо за обратную связь
                        </Typography>
                        <Typography component="div" style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 800,
                            fontSize: '22px',
                            lineHeight: '26px',
                            marginTop: '12px',
                            marginBottom: '10px',
                        }}>
                            Расскажите с чем связана Ваша оценка
                        </Typography>
                        <ExRating value={Number(main.quality)} desc={false}  onChange={main.setQuality} sx={{fontSize: (main.windowDimensions.width > 500 ? '80px' : '15vw')}} name="quality"
                                  legend="Качество интернета"/>
                        <ExRating value={Number(main.cost)} desc={false}  onChange={main.setCost} sx={{fontSize: (main.windowDimensions.width > 500 ? '80px' : '15vw')}} name="cost" legend="Качество цифрового ТВ"/>
                        <ExRating value={Number(main.staff)} desc={false}  onChange={main.setStaffQuality} sx={{fontSize: (main.windowDimensions.width > 500 ? '80px' : '15vw')}} name="staff"
                                  legend="Служба технической поддержки"/>
                        <Typography component="div" style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '15px',
                            lineHeight: '18px',
                            marginBottom: '17px',
                            marginTop: '16.8px'
                        }}>
                            Сообщение для руководителя
                        </Typography>
                        <textarea name="comment" value={main.comment} style={{
                            background: '#F7F7F7',
                            height: '10vh',
                            width: '90%',
                            border: '1px solid rgba(152, 148, 148, 0.4)',
                            borderRadius: '6px'
                        }} onChange={main.setComment}/>

                            <Button onClick={handleSubmit}
                                    style={{
                                        borderRadius: '7.58261px',
                                        width: '92%',
                                        height: '6vh',
                                        minHeight: '50px',
                                        background: '#0055FE', color: 'white', fontFamily: 'Rubik',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '17px',
                                        marginTop: '32px',
                                        textTransform: 'none'
                                    }} disabled={!main.quality || !main.cost || !main.staff}>
                                Оценить
                            </Button>


                    </Box>
                )
                :
                (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'start',
                        flexDirection: 'column',
                        mx: 4,
                    }}>
                        <Typography component="div" style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '15px',
                            lineHeight: '18px',
                            marginTop: '10px'
                        }}>
                            Спасибо за уделенное время
                        </Typography>
                        <Typography component="div" style={{
                            fontFamily: 'Rubik',
                            fontStyle: 'normal',
                            fontWeight: 800,
                            fontSize: '22px',
                            lineHeight: '26px',
                            marginTop: '20px',
                            marginBottom: '40px'
                        }}>
                            Ваш отзыв обязательно будет рассмотрен, а недочёты исправленны!
                        </Typography>

                        <img src={cat} alt="Logo" />
                           </Box>
                )
            }
        </>
    );
}


export default App;
